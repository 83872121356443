export const YEAR_URL_PARAM = "y";
export const REGION_URL_PARAM = "r";

const regexForUrlParam = (param) => new RegExp(`/${param}\\d+/`, "i");

export const updateEventsListUrl = (language, param, newValue) => {
  const url = new URL(window.location);
  if (url.pathname === `/${language}/events/`) {
    //init url path
    url.pathname = `/${language}/events/y0/r0/s0/`;
  }

  url.pathname = url.pathname.replace(
    regexForUrlParam(param),
    `/${param}${newValue}/`
  );
  window.history.replaceState({}, "", url.toString());
};
