import React from "react";
import { formatDate } from "../util/dates";
import { ResultLink } from "../links/links";

export const LastResultCell = ({ lastResult }) => (
  <ResultLink
    result={lastResult}
    text={lastResult.classresult.event.name}
  />
);

export const LastResultDateCell = ({ lastResult }) => (
  <ResultLink
    result={lastResult}
    text={
      lastResult.classresult.event.date &&
      formatDate(new Date(lastResult.classresult.event.date))
    }
  />
);