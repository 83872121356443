import React from "react";
import { formatDate } from "../util/dates";

export const EventDetailInfo = ({ event }) => {
  const totalNumberOfRunners = React.useMemo(
    () =>
      event.classresultSet.reduce(
        (sum, classresult) =>
          sum +
          (event.isRelay
            ? classresult.teammemberresultSet.count
            : classresult.resultSet.count),
        0
      ),
    [event.classresultSet]
  );
  return (
    <p>
      <span className="oi oi-calendar" /> Dato:{" "}
      {formatDate(new Date(event.date))}
      {event.mapname && (
        <>
          <br />
          <span className="oi oi-map" /> Kart: {event.mapname}
        </>
      )}
    </p>
  );
};
