import React, { useState } from "react";
import PersonList from "./PersonList";
import SearchField, { getNameFromWindowLocation } from "./SearchField";
import QueryResultMessage from "./QueryResultMessage";
import { PERSON_SEARCH_QUERY } from "./personQuery";
import { useFixedQuery } from "../util/api";

const personQueryDataToPersons = (data) => {
  const dataIsEmpty = !data;
  return dataIsEmpty
    ? []
    : data.persons.edges
        .map((edge) => edge.node)
        .map((person) => {
          const { lastResult } = person;
          const lastEventName = lastResult?.classresult?.event?.name;
          const lastEventDate = lastResult?.classresult?.event?.date;

          return {
            ...person,
            lastEventName,
            lastEventDate,
          };
        });
};

const isNameTooShort = (name) => {
  return name.trim().replace(/\s/g, "").length < 3;
};

const searchResultMessage = (name, persons, loading, error) => {
  if (name === null) {
    return "";
  } else if (isNameTooShort(name)) {
    return "Du må skrive minst tre bokstaver i søkefeltene.";
  } else if (error) {
    console.error(error);
    return "En feil oppsto... Prøv på nytt senere.";
  } else if (loading) {
    return "Henter data.";
  } else if (persons.length === 0) {
    return "Ingen treff";
  }
};

const Person = () => {
  const [name, setName] = useState(getNameFromWindowLocation());

  const pageSize = 50;
  const { loading, error, data, fetchMore } = useFixedQuery(
    PERSON_SEARCH_QUERY,
    {
      variables: {
        name,
        first: pageSize,
        offset: 0,
      },
      skip: name == null || isNameTooShort(name),
    }
  );
  const persons = personQueryDataToPersons(data);
  const totalPersonsCount = data && data.persons ? data.persons.count : 0;

  return (
    <>
      <SearchField
        labelText={"Navn"}
        onSubmit={(value) => {
          const newName = value.trim();
          setName(newName);
          const url = new URL(window.location);
          url.searchParams.set("name", newName);
          window.history.replaceState({}, "", url);
        }}
      />
      <QueryResultMessage>
        {searchResultMessage(name, persons, loading, error)}
      </QueryResultMessage>
      {persons.length > 0 && (
        <>
          <PersonList persons={persons} />
          {persons.length < totalPersonsCount && (
            <>
              <button
                key="loadMoreBtn"
                type="button"
                className="btn btn-primary ml-1"
                onClick={() =>
                  fetchMore({
                    variables: {
                      offset: data.persons.edges.length,
                    },
                  })
                }
              >
                Last flere
              </button>
            </>
          )}
          <p>
            Viser {persons.length} av {totalPersonsCount} treff
          </p>
        </>
      )}
    </>
  );
};
export default Person;
