import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const PERSON_SEARCH_QUERY = gql`
  query ($name: String!, $first: Int, $offset: Int) {
    persons(name: $name, first: $first, offset: $offset) {
      count
      edges {
        node {
          id
          name
          resultCount
          lastResult {
            id
            classresult {
              event {
                id
                date
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PersonPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  resultCount: PropTypes.number,
  lastEventName: PropTypes.string,
  lastEventDate: PropTypes.string,
  lastResult: PropTypes.shape({
    id: PropTypes.string,
    classresult: PropTypes.shape({
      event: PropTypes.shape({
        id: PropTypes.string,
        date: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  }),
});
