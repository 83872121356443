export class Year {
  static ALL_YEARS = new Year("0");

  constructor(year) {
    this.year = year;
  }

  static yearOrAllYears(year) {
    if (year && year.year) return year;
    return Year.ALL_YEARS;
  }

  isAllYears() {
    return this.year === Year.ALL_YEARS.year;
  }

  /**
   * @returns {Date|undefined} the first day of the year, or undefined to represent no start date
   */
  firstDateOfYear() {
    if (this.year === Year.ALL_YEARS.year) return undefined;
    return new Date(`${this.year}-01-01`);
  }

  /**
   * @returns {Date|undefined} the last day of the year, or undefined to represent no end date
   */
  lastDateOfYear() {
    if (this.year === Year.ALL_YEARS.year) return undefined;
    return new Date(`${this.year}-12-31`);
  }

  toString() {
    return `year: ${this.year}`;
  }
}

export class Region {
  static ALL_REGIONS = new Region(undefined, undefined);
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  get idOr0() {
    return this.id || 0;
  }

  toString() {
    return `region: ${this.id}, ${this.name}`;
  }
}
