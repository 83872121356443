import React from "react";
import { YearFiltering } from "./YearFiltering";
import { RegionFiltering } from "./RegionFiltering";
import { useLanguage } from "../util/hooks";
import { Region, Year } from "./models";
import { useFixedQuery } from "../util/api";
import { METADATA_QUERY } from "./metdataQuery";
import {
  REGION_URL_PARAM,
  updateEventsListUrl,
  YEAR_URL_PARAM,
} from "./urlUtils";

export const EventsHeaderButtons = ({ setFromDate, setToDate, setRegion }) => {
  const { loading, error, data } = useFixedQuery(METADATA_QUERY);

  const language = useLanguage();
  const onUpdateYear = (year) => {
    updateEventsListUrl(language, YEAR_URL_PARAM, year.year);
    setFromDate(year.firstDateOfYear());
    setToDate(year.lastDateOfYear());
  };

  const onUpdateRegion = (region) => {
    // 0 is used in URL to define 'all'
    updateEventsListUrl(language, REGION_URL_PARAM, region.idOr0);
    setRegion(region);
  };

  if (error) {
    console.error(
      `An error occurred loading metadata for event list. Error:`,
      error
    );
    return <p>Det oppsto en feil. Vennligst prøv igjen.</p>;
  }

  if (loading && !data) {
    return <p>Laster...</p>;
  }

  const years = data.metadata.years.map((el) => new Year(el));
  const regions = data.metadata.regions.map((el) => new Region(el.id, el.name));

  return (
    <div className="row">
      <div className="col">
        {/*TODO: better support for filtering of years? buttons for from / to */}
        <YearFiltering onUpdate={onUpdateYear} allYears={years} />
        <RegionFiltering onUpdate={onUpdateRegion} allRegions={regions} />
      </div>
    </div>
  );
};
