import ReactTooltip from "react-tooltip";

export const Tooltip = (props) => (
  <>
    <span data-tip={props.tooltipText}>
      {props.children}
      <span className="oi oi-info text-primary mx-1"></span>
    </span>
    <ReactTooltip className={"tooltip"} place={"right"} effect={"solid"} />
  </>
);
