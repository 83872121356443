import React from "react";
import { formatDateHourMinute } from "../util/dates";
import { useFixedQuery } from "../util/api";
import { LAST_UPDATE_QUERY } from "./lastUpdateQuery";

export const LastUpdate = (props) => {
  const { error, data } = useFixedQuery(LAST_UPDATE_QUERY);

  if (error) {
    console.error(error);
    return null;
  }

  if(!data) return null;
  const lastDataUpdate = formatDateHourMinute(data.statistics.lastDataUpdate);
  const lastCodeUpdate = formatDateHourMinute(data.statistics.lastCodeUpdate);
  return (
    <section>
      <div className="text-center">
        <span className="oi oi-reload" /> {' '}
        Løp sist oppdatert: {lastDataUpdate}
      </div>
      <div className="text-center">
        <span className="oi oi-code" /> {' '}
        Kode sist oppdatert {lastCodeUpdate}
      </div>
    </section>
  );
};
