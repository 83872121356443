import React from "react";

const QueryResultMessage = ({ children }) => {
  return (
    <p className="mt-2 mb-0" style={{ minHeight: "30px" }}>
      {children}
    </p>
  );
};

export default QueryResultMessage;
