import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const METADATA_QUERY = gql`
  query {
    metadata {
      years
      regions {
        id
        name
      }
    }
  }
`;

export const MetadataPropType = PropTypes.shape({
  years: PropTypes.array,
  regions: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
});
