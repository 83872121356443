import React from "react";

export const EventsHeader = ({ fromDate, toDate, region }) => {
  // for now fromDate and toDate is the same year
  const yearSubHeadingAdditional = fromDate ? `i ${fromDate.getFullYear()} ` : "";
  const regionSubHeadingAdditional = region.name ? `i ${region.name} ` : "";

  return (
    <div className="row">
      <div className="col">
        <h2>Løp {yearSubHeadingAdditional} {regionSubHeadingAdditional}</h2>
      </div>
    </div>
  );
};
