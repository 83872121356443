import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const EVENT_LIST_SEARCH_QUERY = gql`
  query ($from: Date!, $to: Date!, $regionId: Int, $first: Int, $offset: Int) {
    events(
      from: $from
      to: $to
      first: $first
      regionId: $regionId
      offset: $offset
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          date
          organiser
          url
          source
        }
      }
    }
  }
`;

export const EventListPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  organiser: PropTypes.string,
  url: PropTypes.string,
  source: PropTypes.string,
});
