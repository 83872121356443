import React from "react";
import { useFixedQuery } from "../util/api";
import { useParams } from "react-router-dom";
import { EVENT_SEARCH_QUERY } from "./eventDetailQuery";
import EventClassTable from "./EventClassTable";
import { EventDetailInfo } from "./EventDetailInfo";
import { formatDateHourMinute } from "../util/dates";

export const EventDetail = (props) => {
  let { eventId } = useParams();
  const { loading, error, data } = useFixedQuery(EVENT_SEARCH_QUERY, {
    variables: {
      id: eventId,
    },
  });
  if (error) {
    console.error(
      `An error occured loading event detail for event id ${eventId}: Error:`,
      error
    );
    return <p>Det oppsto en feil. Vennligst prøv igjen.</p>;
  }
  if (!data) return <p>Laster...</p>;
  const { event } = data;

  const title = event.name + (event.organiser ? `, ${event.organiser}` : "");
  const lastUpdateString = formatDateHourMinute(event.lastupdate);

  return (
    <>
      <h2>{title}</h2>
      <EventDetailInfo event={event} />
      <div>
        <h3>Klasser</h3>
        <EventClassTable
          isRelay={event.isRelay}
          classresults={event.classresultSet}
        />
      </div>
      <div className="eventbottom">
        {event.classresultSet.length > 0 && (
          <>
            <span className="oi oi-people" />{" "}
            <a href={"control_encounter/"}>Hvem traff jeg på posten?</a>
            <br />
          </>
        )}
        {event.url && (
          <>
            <span className="oi oi-external-link" />{" "}
            <a href={event.url} target="_blank">
              Åpne {event.source}
            </a>
            <br />
          </>
        )}
        <span className="oi oi-reload" /> Sist oppdatert: {lastUpdateString}{" "}
      </div>
    </>
  );
};
