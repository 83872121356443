import React, { useMemo } from "react";
import Table from "../util/Table";
import { classresultSetPropTypes } from "./eventDetailQuery";
import PropTypes from "prop-types";
import { useLanguage } from "../util/hooks";
import { ClassresultLink } from "../links/links";
import { Tooltip } from "../util/Tooltip";

const EventClassTable = ({ isRelay, classresults }) => {
  const language = useLanguage();

  const columns = useMemo(() => {
    const secondColumn = isRelay
      ? {
          Header: "Lag",
          accessor: "teamresultSet",
          Cell: ({ value }) => <span>{value.length}</span>,
        }
      : {
          Header: "Løype",
          accessor: "course.name",
          Cell: (data) => {
            const classResult = data.row.original;
            if (!classResult.course) return null;
            return (
              <a href={`/${language}/events/course/${classResult.course.id}/`}>
                {classResult.course.name}
              </a>
            );
          },
        };
    return [
      {
        Header: "Navn",
        accessor: "name",
        Cell: (data) => <ClassresultLink classresult={data.row.original} />,
        Footer: ({ rows }) => (
          <Tooltip tooltipText="På siden for alle løpere kan du krysse av og plotte felles strekk for løpere i ulike klasser">
            <strong>
              <a href={"results/"}>Alle løpere</a>
            </strong>
          </Tooltip>
        ),
      },
      secondColumn,
      {
        Header: "Løpere",
        accessor: isRelay ? "teammemberresultSet.count" : "resultSet.count",
        id: "runnerCount",
        Cell: ({ value }) => <span>{value}</span>,
        Footer: ({ rows }) => {
          const total = React.useMemo(
            () => rows.reduce((sum, row) => row.values.runnerCount + sum, 0),
            [rows]
          );
          return <>{total}</>;
        },
      },
      {
        Header: "Strekktid",
        accessor: "noneIgnoreThis",
        Cell: (data) => {
          const event = data.row.original;
          return (
            <a
              href={`/${language}/events/classresult/${event.id}/?splits=&crid=${event.id}`}
            >
              <span className="oi oi-timer" /> Strekktid
            </a>
          );
        },
      },
    ];
  }, [isRelay, language]);

  return <Table columns={columns} data={classresults} />;
};

EventClassTable.propTypes = {
  isRelay: PropTypes.bool.isRequired,
  classresults: classresultSetPropTypes.isRequired,
};

export default EventClassTable;
