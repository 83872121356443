import React, { useMemo } from "react";
import { useFixedQuery } from "../util/api";
import { STATISTIC_SEARCH_QUERY } from "./statsisticQuery";

export const Statistics = (props) => {
  const { loading, error, data } = useFixedQuery(STATISTIC_SEARCH_QUERY );

  if(error) {
    console.error(error);
  }


  const {persons, events, classes, results, splits} = data ? data.statistics :
    {
      //Placeholder data
      persons: '',
      events: '',
      classes: '',
      results: '',
      splits: '',
    };
  return (
    <>
      <h3>Statistikk</h3>
      Resultatdatabasen inneholder akkurat nå omtrent
      <table>
        <tbody>
          <tr>
            <td align="right">{persons}</td>
            <td>løpere</td>
          </tr>
          <tr>
            <td align="right">{events}</td>
            <td>løp</td>
          </tr>
          <tr>
            <td align="right">{classes}</td>
            <td>klasser</td>
          </tr>
          <tr>
            <td align="right">{results}</td>
            <td>deltakelser</td>
          </tr>
          <tr>
            <td align="right">{splits}</td>
            <td>strekktider</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
