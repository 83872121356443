import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const EVENT_SEARCH_QUERY = gql`
  query ($id: Int!) {
    event(id: $id) {
      id
      name
      date
      organiser
      url
      lastupdate
      source
      mapname
      isRelay
      classresultSet {
        id
        name
        relay
        teamresultSet {
          id
        }
        teammemberresultSet {
          count
        }
        resultSet {
          count
        }
        course {
          id
          name
        }
      }
    }
  }
`;

const teamresultSetPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
  })
);
const resultSetPropTypes = PropTypes.shape({
  count: PropTypes.number,
});
export const classresultSetPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    relay: PropTypes.bool,
    teamresultSet: teamresultSetPropTypes,
    resultSet: resultSetPropTypes,
    teammemberresultSet: resultSetPropTypes,
    course: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  })
);

export const EventPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  organiser: PropTypes.string,
  url: PropTypes.string,
  lastupdate: PropTypes.string,
  method: PropTypes.string,
  mapname: PropTypes.string,
  isRelay: PropTypes.bool,
  classresultSet: classresultSetPropTypes,
});
