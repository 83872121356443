import React, { useMemo } from "react";
import Table from "../util/Table";
import { formatDate } from "../util/dates";
import { EventLink } from "../links/links";

export const EventListTable = ({ events }) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Dato",
        accessor: "date",
        Cell: ({ value }) => formatDate(new Date(value)),
      },
      {
        Header: "Navn",
        accessor: "name",
        Cell: (data) => {
          const event = data.row.original;
          return <EventLink event={event} text={event.name} />;
        },
      },
      {
        Header: "Arrangør",
        accessor: "organiser",
        Cell: ({ value }) => value,
      },
      {
        Header: "Kilde",
        accessor: "source",
        Cell: (data) => {
          const event = data.row.original;
          if (!event.url) return null;
          return <a href={event.url}>{event.source}</a>;
        },
      },
    ];
  }, []);

  return <Table columns={columns} data={events} showFooter={false} />;
};
