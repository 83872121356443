import React, { useState } from "react";
import EventList from "./EventList";
import { EventsHeader } from "./EventsHeader";
import { Region } from "./models";
import { EventsHeaderButtons } from "./EventsHeaderButtons";

export const Events = (props) => {
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [region, setRegion] = useState(Region.ALL_REGIONS);

  return (
    <>
      <EventsHeader fromDate={fromDate} toDate={toDate} region={region} />
      <EventsHeaderButtons
        setFromDate={setFromDate}
        setToDate={setToDate}
        setRegion={setRegion}
      />

      <EventList
        fromDate={fromDate}
        toDateInclusive={toDate}
        regionId={region?.id}
      />
    </>
  );
};
