import { useLanguage } from "../util/hooks";
import React from "react";
import { Link } from "react-router-dom";

export const EventLink = ({ event, text }) => (
  <Link to={`/${useLanguage()}/events/${event.id}/`}>{text}</Link>
);

export const ClassresultLink = ({ classresult }) => (
  <a href={`/${useLanguage()}/events/classresult/${classresult.id}/`}>
    {classresult.name}
  </a>
);

export const ResultLink = ({ result, text }) => (
  <a href={`/${useLanguage()}/events/result/${result.id}/`}>{text}</a>
);
