import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useFixedQuery } from "../util/api";
import { EVENT_LIST_SEARCH_QUERY } from "./eventListQuery";
import { EventListTable } from "./EventListTable";
import InfiniteScroll from "react-infinite-scroller";
import { formatDateIso8601 } from "../util/dates";
import { LastUpdate } from "../last-update/LastUpdate";

const EventList = ({
  // Default to all events
  fromDate = new Date("2000-01-01"),
  toDateInclusive = new Date("2099-01-01"),
  regionId,
}) => {
  const pageSize = 10;
  const { loading, error, data, fetchMore } = useFixedQuery(
    EVENT_LIST_SEARCH_QUERY,
    {
      variables: {
        from: formatDateIso8601(fromDate),
        to: formatDateIso8601(toDateInclusive),
        regionId: regionId,
        first: pageSize,
        offset: 0,
      },
    }
  );

  const events = useMemo(() => {
    const isLoadingOrNoData = loading || !data;
    return isLoadingOrNoData ? [] : data.events.edges.map((el) => el.node);
  }, [data]);

  if (error) {
    console.error(`An error occurred loading event list. Error:`, error);
    return <p>Det oppsto en feil. Vennligst prøv igjen.</p>;
  }

  // Should display 'load more' one each pageSize*5 events loaded (50 p.t.)
  const shouldFetchMore = events.length % (pageSize * 5) !== 0;
  const hasMoreData = !data ? true : data.events.pageInfo.hasNextPage;
  const fetchNextData = () => {
    if (!loading) {
      fetchMore({
        variables: {
          offset: events.length,
        },
      });
    }
  };

  return (
    <div id="eventlist">
      <InfiniteScroll
        loadMore={fetchNextData}
        hasMore={shouldFetchMore && hasMoreData}
        loader={<p key={0}>Laster...</p>}
      >
        <EventListTable events={events} />
      </InfiniteScroll>
      <div>
        <span>Viser {events.length} {!hasMoreData && <>av {events.length} </>} løp{" "}</span>
        {hasMoreData && <button className="btn btn-primary ml-1" onClick={fetchNextData}>Last mer</button>}
      </div>

      <LastUpdate/>

    </div>
  );
};

EventList.propTypes = {
  fromDate: PropTypes.instanceOf(Date),
  toDateInclusive: PropTypes.instanceOf(Date),
  regionId: PropTypes.string,
};

export default EventList;
