import {
  ApolloClient,
  InMemoryCache,
  NetworkStatus,
  useQuery,
} from "@apollo/client";

export const client = new ApolloClient({
  uri: "/nb/events/api",
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          persons: {
            // Hvis jeg har forstått korrekt:
            //  Keyargs er keyen benyttet i cachen (dvs verdien søk-input-feltet som så mates inn i queryet via parameteret "name" (KeyArgs)).
            // Uansett: Uten denne fungerer verken caching eller paginering i søket.
            // https://www.apollographql.com/docs/react/pagination/key-args/
            keyArgs: ["name"],
            merge(existing = {}, incoming) {
              const existingEdges = existing.edges ? existing.edges : [];
              return {
                ...existing,
                ...incoming,
                edges: [...existingEdges, ...incoming.edges],
              };
            },
          },
          events: {
            keyArgs: ["from", "to", "regionId"],
            merge(existing = {}, incoming) {
              const existingEdges = existing.edges ? existing.edges : [];
              return {
                ...existing,
                ...incoming,
                edges: [...existingEdges, ...incoming.edges],
              };
            },
          },
        },
      },
    },
  }),
  name: "orre-graphql-client",
  version: "0.0.1",
});

export const useFixedQuery = (query, options) => {
  const { loading, error, data, fetchMore, networkStatus } = useQuery(
    query,
    options
  );
  if (networkStatus === NetworkStatus.ready) {
    // If status is ready, error should be undefined even though useQuery may return error from previous query
    // See https://github.com/apollographql/apollo-client/issues/7934
    return { loading, undefined, data, fetchMore };
  } else {
    return { loading, error, data, fetchMore };
  }
};
