import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { PersonPropType } from "./personQuery";
import Table from "../util/Table";
import { LastResultCell, LastResultDateCell } from "./LastResultTableData";

// Heavy use of react-table
// Basically the quick start and sorting example tutorial with some modifications
// https://react-table.tanstack.com/docs/quick-start
// https://react-table.tanstack.com/docs/examples/sorting
const PersonList = ({ persons }) => {
  const sortAlphabetically = (a, b) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
    return a.localeCompare(b);
  };
  const sortColumnAlphabetically = useCallback((rowA, rowB, columnId) => {
    return sortAlphabetically(rowA.values[columnId], rowB.values[columnId]);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Navn",
        accessor: "name", // accessor is the "key" in the data
        Cell: (data) => {
          const person = data.row.original;
          return <a href={person.id + "/"}>{person.name}</a>;
        },
      },
      {
        Header: "Siste resultat",
        accessor: "lastEventName",
        Cell: (value) => {
          const person = value.row.original;
          return person.lastResult ? (
            <LastResultCell lastResult={person.lastResult} />
          ) : null;
        },
        sortType: sortColumnAlphabetically,
      },
      {
        Header: "Dato for siste resultat",
        accessor: "lastEventDate",
        Cell: (value) => {
          const person = value.row.original;
          return person.lastResult ? (
            <LastResultDateCell lastResult={person.lastResult} />
          ) : null;
        },
        sortType: sortColumnAlphabetically,
      },
      {
        Header: "Antall løp",
        accessor: "resultCount",
        Cell: ({ value }) => value,
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      data={persons}
      disableSortBy={false}
      showFooter={false}
    />
  );
};

PersonList.propTypes = {
  persons: PropTypes.arrayOf(PersonPropType),
};

export default PersonList;
