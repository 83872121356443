import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const STATISTIC_SEARCH_QUERY = gql`
  query  {
    statistics {
      events
      classes
      results
      splits
      persons
    }
  }
`;

export const StatisticPropType = PropTypes.shape({
  events: PropTypes.number.isRequired,
  classes: PropTypes.number.isRequired,
  results: PropTypes.number.isRequired,
  splits: PropTypes.number.isRequired,
  persons: PropTypes.number.isRequired,
});
