import React from "react";

export const History = (props) => {
  return (
    <>
      <h3>Historie</h3>
      <dl>
        <dt>2011-06-14</dt>
        <dd>
          Versjon 1 av OrRe lansert, med importering av resultater fra STBIK,
          internløp og brikkesys. Noe av funksjonaliteten:
          <ul>
            <li>Samling av resultater fra flere kilder på ett sted </li>
            <li>Plotting av løpere mot hverandre </li>
            <li>
              Utregning av løpskapasitet (LK) for å anslå hvor rask løperen er{" "}
            </li>
          </ul>
        </dd>
        <dt>2011-09-07</dt>
        <dd>OrRe flyttet til orre.nonempty.org.</dd>
        <dt>2012-07-27</dt>
        <dd>
          Versjon 2 av OrRe. Noe av den nye funksjonaliteten:
          <ul>
            <li>Mulighet for å vise strekktider kun for valgte løpere</li>
            <li>
              Vesentlig raskere lasting av en del sider (som tidligere var
              trege)
            </li>
            <li>
              Ny variabel, OrienteringsKapasitet (OK), som skal fortelle noe om
              orienteringsferdigheter (NB: kun veiledende!){" "}
            </li>
          </ul>
        </dd>
        <dt>2012-07-29</dt>
        <dd>
          Mulighet til å plotte grafer og vise strekktider for felles strekk
          blant løpere som har løpt ulike klasser.
        </dd>
        <dt>2013-08-05</dt>
        <dd>
          Mulighet for å vise alle som har løpt samme løype, uavhengig av
          klasse, samt "Hvem traff jeg på posten".
        </dd>
        <dt>2013-09-13</dt>
        <dd>
          Ny beregningsmetode for OK og LK. Merking av lette og vanskelige
          strekk.
        </dd>
        <dt>2013-09-28</dt>
        <dd>
          Inndeling i regioner og serier, automatisk import av alle løp fra
          brikkesys og Eventor.
        </dd>
        <dt>2014-09-07</dt>
        <dd>
          Korrekt import av stafetter. Det er kun resultater fra Brikkesys som
          inneholder strekktider.
        </dd>
        <dt>2015-05-03</dt>
        <dd>
          Interaktive plott vha.{" "}
          <a href="http://www.highcharts.com">Highcharts</a>.
        </dd>
        <dt>2016-05-04</dt>
        <dd>
          OrRe fikk sitt eget domene på{" "}
          <a href="https://oresultater.no">oresultater.no</a>!
        </dd>
        <dt>2016-12-23</dt>
        <dd>
          <ul>
            <li>
              Import av svenske løp fra{" "}
              <a href="http://eventor.orientering.se">svenske Eventor</a>, samt
              oversettelse til svensk.
            </li>
            <li>Plott av klokketid, for å f.eks. se hvem som hang på hvem!</li>
          </ul>
        </dd>
      </dl>
    </>
  );
};
