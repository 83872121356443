import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const LAST_UPDATE_QUERY = gql`
  query  {
    statistics {
      lastDataUpdate
      lastCodeUpdate
    }
  }
`;

export const LastUpdatePropType = PropTypes.shape({
  lastDataUpdate: PropTypes.string.isRequired,
  lastCodeUpdate: PropTypes.string.isRequired,
});
