import React from "react";

export const FilteringButton = ({ children, isActive, onClick }) => (
  <a
    className={`dropdown-item ${isActive && "active"}`}
    onClick={onClick}
    style={{ cursor: "pointer" }}
  >
    {children}
  </a>
);
