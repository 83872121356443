import React, { useState } from "react";
import PropTypes from "prop-types";

export const getNameFromWindowLocation = () => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has("name")) {
    return urlParams.get("name");
  } else if (urlParams.has("given") || urlParams.has("family")) {
    const given = urlParams.has("given") ? urlParams.get("given") : "";
    const family = urlParams.has("family") ? urlParams.get("family") : "";
    return `${given} ${family}`;
  } else {
    return null;
  }
};

const SearchField = ({ labelText, onSubmit }) => {
  const [value, setValue] = useState((getNameFromWindowLocation() || "").trim());

  const onSearch = () => onSubmit(value);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <div className="input-group">
      <label htmlFor="inputPersonName" className="sr-only">
        {labelText}
      </label>

      <input
        type="text"
        className="form-control col-md-6"
        id={"inputPersonName"}
        placeholder={labelText}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onSubmit={onSearch}
        autoComplete={"off"}
        onKeyDown={handleKeyDown}
        data-testid="searchfield"
      />
      <button type="button" className="btn btn-primary ml-1" onClick={onSearch}>
        <i className="fas fa-search" /> Søk
      </button>
    </div>
  );
};

SearchField.propTypes = {
  labelText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

export default SearchField;
