import React from "react";
import { History } from "./History";
import { Statistics } from "./Statistics";

export const About = (props) => {
  return (
    <section>
      <div style={{ float: "left" }}>
        <img
          src="/media/logo160.jpg"
          style={{ float: "left", margin: "15px" }}
          alt="Dette er en Orre/Orrfugl"
        />
        <h2>Om OrRe</h2>
        <p>
          OrRe (OrienteringsResultater) gir deg resultater fra orienteringsløp i
          Norge og Sverige. OrRe analyserer resultater og gir deg mulighet til å
          vise resultatlistene, strekktider og grafer for ditt løp. I stedet for
          å sjekke resultater på både{" "}
          <a href="http://www.brikkesys.no">Brikkesys</a>,{" "}
          <a href="http://eventor.orientering.no">Eventor</a>,{" "}
          <a href="http://orientering.stbik.no">
            Sør-Trøndelag bedriftsidrettskrets
          </a>{" "}
          og{" "}
          <a href="http://www.ntnu.no/ntnu-bil/grupper/o/">NTNUs internløp</a>,
          kan man nøye seg med én side. OrRe ble skapt våren 2011 av Halvor
          Lund. Målet var å lage en enhetlig og brukervennlig side for
          resultater fra orienteringsløp og -konkurranser.
        </p>
        <p>
          Støtt gjerne videre drift og utvikling av OrRe via{" "}
          <a href="https://www.buymeacoffee.com/orre" target="new">
            Buy me a coffee!{" "}
            <img
              src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
              height="20pt"
            />
          </a>{" "}
          Vi setter også pris på tilbakemeldinger og forslag til forbedringer av
          OrRe. Ta kontakt på{" "}
          <a href="mailto:orre@oresultater.no">orre@oresultater.no</a> eller på{" "}
          <a
            href="https://www.facebook.com/oresultater"
            target="_blank"
            rel="noopener"
          >
            <img src="/media/facebook.png" height="16pt" alt={"f"} />
            acebook
          </a>
          .
        </p>
      </div>
      <Statistics />
      <History />
    </section>
  );
};
