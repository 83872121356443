import React, { useEffect, useState } from "react";
import { FilteringButton } from "./FilteringButton";
import { Year } from "./models";
import { useParams } from "react-router-dom";

export const YearFiltering = ({ onUpdate, allYears }) => {
  // If navigated to path /<lang>/events/y<year>/r<region>/s0/, else yearParam is undefined
  let yearParam = new Year(useParams().year);
  const [year, setYear] = useState(Year.yearOrAllYears(yearParam));

  useEffect(() => {
    onUpdate(year);
  }, [year]);

  const activeYear = year.isAllYears() ? "Alle" : year.year;

  return (
    <div className="btn-group mr-1">
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        År: {activeYear}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <FilteringButton
          isActive={year.isAllYears()}
          onClick={() => setYear(Year.ALL_YEARS)}
        >
          Alle
        </FilteringButton>

        {allYears.map((yearWithData) => (
          <FilteringButton
            key={yearWithData.year}
            isActive={yearWithData.year === year.year}
            onClick={() => setYear(yearWithData)}
          >
            {yearWithData.year}
          </FilteringButton>
        ))}
      </div>
    </div>
  );
};
