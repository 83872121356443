import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import Person from "./person/Person";
import { client } from "./util/api";
import { Portal } from "./util/Portal";
import { EventDetail } from "./event-detail/EventDetail";
import { About } from "./about/About";
import { Events } from "./event-list/Events";
import { PersonDetail } from "./person-detail/PersonDetail";

const App = () => (
  <ApolloProvider client={client}>
    <Routes>
      <Route
        path="/:language/events/person/"
        element={
          <Portal container={contentContainer}>
            <Person />
          </Portal>
        }
      />
      <Route
        path="/:language/events/person/:personId/"
        element={
          <Portal container={contentContainer}>
            <PersonDetail />
          </Portal>
        }
      />
      <Route
        path="/:language/events/about/"
        element={
          <Portal container={contentContainer}>
            <About />
          </Portal>
        }
      />
      <Route
        path="/:language/events/:eventId/"
        element={
          <Portal container={contentContainer}>
            <EventDetail />
          </Portal>
        }
      />
      <Route
        path="/:language/events/"
        element={
          <Portal container={contentContainer}>
            <Events />
          </Portal>
        }
      />
      <Route
        path="/:language/events/y:year/r:region/s:seriesId"
        element={
          <Portal container={contentContainer}>
            <Events />
          </Portal>
        }
      />
    </Routes>
  </ApolloProvider>
);

const contentContainer = document.getElementById("react_content_container");
const root = createRoot(contentContainer);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
