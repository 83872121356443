import React, { useEffect, useState } from "react";
import { FilteringButton } from "./FilteringButton";
import { Region } from "./models";
import { useParams } from "react-router-dom";

export const RegionFiltering = ({ onUpdate, allRegions = [] }) => {
  const regionIdParam = useParams().region;
  const regionFromParam = allRegions.find((el) => el.id === regionIdParam);
  const initialRegion = regionFromParam ? regionFromParam : Region.ALL_REGIONS;

  const [selectedRegion, setSelectedRegion] = useState(initialRegion);

  useEffect(() => {
    onUpdate(selectedRegion);
  }, [selectedRegion]);

  const activeRegion = selectedRegion.name ? selectedRegion.name : "Alle";

  return (
    <div className="btn-group">
      <button
        className="btn btn-primary dropdown-toggle"
        type="button"
        id="regionDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Region: {activeRegion}
      </button>
      <div className="dropdown-menu" aria-labelledby="regionDropdown">
        <FilteringButton
          isActive={selectedRegion.id === 0}
          onClick={() => setSelectedRegion(Region.ALL_REGIONS)}
        >
          Alle
        </FilteringButton>

        {allRegions.map((region) => (
          <FilteringButton
            key={region.id}
            isActive={region.id === selectedRegion.id}
            onClick={() => setSelectedRegion(region)}
          >
            {region.name}
          </FilteringButton>
        ))}
      </div>
    </div>
  );
};
