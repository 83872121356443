import React, { useMemo } from "react";
import { useFixedQuery } from "../util/api";
import { useParams } from "react-router-dom";
import { PERSON_QUERY } from "./personDetailQuery";
import Table from "../util/Table";
import { formatDate } from "../util/dates";
import { ClassresultLink, EventLink, ResultLink } from "../links/links";
import { Tooltip } from "../util/Tooltip";
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts'
import { intRange } from "../util/numbers";

export const PersonDetail = (props) => {
  let { personId } = useParams();
  const { loading, error, data } = useFixedQuery(PERSON_QUERY, {
    variables: {
      id: personId,
    },
  });

  const formatPercent = (value) =>
    value != null ? (value * 100).toFixed(0) + "%" : "";

  const formatTime = (seconds) =>
    Math.floor(seconds / 3600) +
    ":" +
    String(Math.floor(seconds / 60) % 60).padStart(2, "0") +
    ":" +
    String(seconds % 60).padStart(2, "0");

  const columns = useMemo(
    () => [
      {
        Header: "Dato",
        accessor: "node.classresult.event.date",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: () => (
          <Tooltip tooltipText="Klikk på løpsnavn for å vise all info om løpet">
            Navn
          </Tooltip>
        ),
        accessor: "node.classresult.event.name",
        Cell: ({ row }) => {
          const result = row.original.node;
          return (
            <EventLink
              event={result.classresult.event}
              text={result.classresult.event.name}
            ></EventLink>
          );
        },
      },
      {
        Header: () => (
          <Tooltip tooltipText="Klikk på klassenavn for å vise alle løpere i klassen">
            Klasse
          </Tooltip>
        ),
        accessor: "node.classresult.name",
        Cell: ({ row }) => (
          <ClassresultLink classresult={row.original.node.classresult} />
        ),
      },
      {
        Header: (
          <Tooltip tooltipText="Klikk på tid for å vise strekktider">
            Tid
          </Tooltip>
        ),
        accessor: "node.time",
        Cell: ({ row }) => (
          <ResultLink
            result={row.original.node}
            text={formatTime(row.original.node.time)}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "node.status",
        Cell: ({ value }) => value,
      },
      {
        Header: "% bak tet",
        accessor: "node.percentBehind",
        Cell: ({ value }) => formatPercent(value / 100),
      },
      {
        Header: "Min/km",
        accessor: "node.minutesPerKilometer",
        Cell: ({ value }) => value?.toFixed(1) ?? null,
      },
      {
        Header: (
          <Tooltip tooltipText="Anslått løpskapasitet (LK). Ved optimal orientering vil løperen ha en snittfart på LK ganger vinnerfart.">
            LK
          </Tooltip>
        ),
        accessor: "node.runcap",
        Cell: ({ value }) => formatPercent(value),
      },
      {
        Header: (
          <Tooltip tooltipText="Anslått orienteringskapasitet (OK). Et mål på hvor godt man orienterer sammenlignet med andre løpere i samme klasse. 50% er middels godt.">
            OK
          </Tooltip>
        ),
        accessor: "node.ocap",
        Cell: ({ value }) => formatPercent(value),
      },
    ],
    []
  );
  if (error) {
    console.error(
      `An error occurred loading person id ${personId}: Error:`,
      error
    );
    return <p>Det oppsto en feil. Vennligst prøv igjen.</p>;
  }
  if (!data) return <p>Laster...</p>;
  const { person } = data;

  const title = person.name;
  const plotResults = person.resultSet.edges
    .map((e) => e.node)
    .filter((r) => r.runcap > 0 && r.ocap > 0)
    .reverse();
  const options2 = {
    xAxis: [
      {
        title: {
          text: "Dato",
        },
      },
    ],
    title: {
      text: "My chart",
    },
    series: [
      {
        marker: {
          enabled: true,
          symbol: "circle",
          fillColor: "rgba(0,0,0,0)",
          lineColor: "rgb(255,0,0)",
          lineWidth: 1,
        },
        states: { hover: { enabled: false } },
        lineWidth: 0,
        id: "markerLk",
        data: [1, 2, 3],
      },
    ],
  };

  const lk = plotResults.map((r, i) => ({
    x: i,
    y: r.runcap * 100,
    name:
      formatDate(r.classresult.event.date) + ": " + r.classresult.event.name,
  }));

  const ok = plotResults.map((r, i) => ({
    x: i,
    y: r.ocap * 100,
    name:
      formatDate(r.classresult.event.date) + ": " + r.classresult.event.name,
  }));

  const average = (values) => values.reduce((a, b) => a+b, 0)/values.length;
  const moving_average = (values, i) => average(values.slice(Math.max(0, i-1), Math.min(values.length, i+2)))

  const lk_values = plotResults.map((r, i) => r.runcap*100);
  const smooth_lk = plotResults.map((r, i) => ({
    x: i,
    y: moving_average(lk_values, i)
  }));
  const ok_values = plotResults.map((r, i) => r.ocap*100);
  const smooth_ok = plotResults.map((r, i) => ({
    x: i,
    y: moving_average(ok_values, i)
  }));

  const numberOfTicks = 10;
  const tickStep = Math.max(Math.ceil(plotResults.length/numberOfTicks), 1);
  const tickPositions = intRange(plotResults.length).filter(i => i % tickStep == 0);

  const options = {
    chart: { type: "line", alignTicks: false, zoomType: "xy", height: 700 },
    credits: { enabled: false },
    annotations: [],
    annotationsOptions: { enabledButtons: false },
    tooltip: { shared: true },
    title: { text: "LK/OK" },
    legend: { title: { text: "Klikk for å velge løpere:" } },
    xAxis: [
      {
        endOnTick: false,
        startOnTick: false,
        tickPositions: tickPositions,
        labels: {
          formatter: function () {
            return formatDate(plotResults[this.value].classresult.event.date);
          },
        },
        title: { text: "Dato" },
      },
    ],
    yAxis: [
      {
        endOnTick: false,
        startOnTick: false,
        title: { text: "LK/OK (%)" },
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        min: 0,
        max: 101,
      },
    ],
    series: [
      {
        marker: {
          enabled: true,
          symbol: "circle",
          fillColor: "rgba(0,0,0,0)",
          lineColor: "rgb(255,0,0)",
          lineWidth: 1,
        },
        states: { hover: { enabled: false } },
        lineWidth: 0,
        id: "q473kLbfJo",
        name: "LK",
        data: lk,
        color: "rgb(255,0,0)",
        tooltip: { valueDecimals: 0 },
        linkedTo: "BaFeAJMsBm",
      },
      {
        marker: {
          enabled: true,
          symbol: "square",
          fillColor: "rgba(0,0,0,0)",
          lineColor: "rgb(255,0,0)",
          lineWidth: 1,
        },
        states: { hover: { enabled: false } },
        lineWidth: 0,
        id: "Ah8zZtXlY2",
        name: "OK",
        data: ok,
        color: "rgb(255,0,0)",
        tooltip: { valueDecimals: 0 },
        linkedTo: "SJuJmPJMzm",
      },
      {
        marker: { enabled: false },
        states: { hover: { enabled: false } },
        lineWidth: 2,
        id: "QlWAsFbfJz",
        name: "LK (glatt)",
        data: smooth_lk,
        color: "rgb(255,0,0)",
        type: "spline",
        enableMouseTracking: false,
        linkedTo: "BaFeAJMsBm",
      },
      {
        marker: { enabled: false },
        states: { hover: { enabled: false } },
        lineWidth: 2,
        id: "kUdKHEoeIk",
        name: "OK (glatt)",
        data: smooth_ok,
        dashStyle: "Dash",
        color: "rgb(255,0,0)",
        type: "spline",
        enableMouseTracking: false,
        linkedTo: "SJuJmPJMzm",
      },
      {
        marker: {
          enabled: true,
          symbol: "circle",
          fillColor: "rgba(0,0,0,0)",
          lineColor: "rgb(255,0,0)",
          lineWidth: 1,
        },
        states: { hover: { enabled: false } },
        lineWidth: 2,
        id: "BaFeAJMsBm",
        name: "LK",
        data: [],
        color: "rgb(255,0,0)",
      },
      {
        marker: { enabled: true, symbol: "square", fillColor: "rgba(0,0,0,0)" },
        states: { hover: { enabled: false } },
        lineWidth: 2,
        id: "SJuJmPJMzm",
        name: "OK",
        data: [],
        dashStyle: "Dash",
        color: "rgb(255,0,0)",
      },
    ],
  };
  return (
    <>
      <h2>{title}</h2>
      Har deltatt i {person.resultSet.count} løp:
      <Table
        columns={columns}
        data={person.resultSet.edges}
        disableSortBy={false}
        showFooter={false}
      />
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};
