import { gql } from "@apollo/client";
import PropTypes from "prop-types";

export const PERSON_QUERY = gql`
  query ($id: Int!) {
    person(id: $id) {
      id
      name
      resultSet {
        count
        edges {
          node {
            id
            time
            status
            percentBehind
            minutesPerKilometer
            runcap
            ocap
            classresult {
              name
              id
              event {
                id
                name
                date
              }
            }
          }
        }
      }
    }
  }
`;